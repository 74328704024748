<template>
  <div
    class="toolbar align-center"
    @click.middle="tab.canClose && close()"
  >
    <v-icon
      class="mr-1"
      v-show="!hideIcon"
    >{{iconComputed}}</v-icon>
    <span
      v-if="tab.title"
      class="taskTitle text-truncate"
    >{{tab.title}}</span>
    <span
      v-else
      class="taskTitle text-truncate"
    >{{tab.typeName}}</span>

    <v-spacer />
    <div v-if="tab.canClose">
      <v-btn
        icon
        small
        class="ml-1"
        @click.stop="close()"
      >
        <v-icon>{{$icon('i.Close')}}</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>

import recentsController from '../recentsController'

export default {
  components: {
  },
  mounted () {
    this.$nextTick(() => this.$triggerResize())
  },
  computed: {
    iconComputed () {
      const i = this.tab.icon
      return i
    }
  },
  data () {
    return {
    }
  },
  methods: {
    is (name) {
      return this.tab.documentType === name
    },
    close () {
      recentsController.close(this.tab)
    }
  },
  watch: {
    tab: {
      handler () {
        this.$triggerResize()
      },
      deep: true
    }
  },
  props: {
    tab: Object,
    hideIcon: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.toolbar
  display flex
  height 36px
  width 100%
</style>
